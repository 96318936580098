export const operationGetUser = `
  query GetUserLogin($where: user_logins_bool_exp) {
    logins:user_logins(where: $where) {
      user_id
      refresh_token
      provider {
        provider
      }
      name
      id
      email
      access_token
      provider_id
    }
  }
`;

export const operationGetAccountUser = `
  query GetUserAccount($id: Int! = 0) {
    user: users_by_pk(id: $id) {
      about
      aboutRich
      bannerImage
      birthday
      countryId
      language
      cvLink
      email
      id
      isActive
      isBetaUser
      isVerified
      isWorker
      lastname
      name
      online
      profileImage
      showBirthday
      totalCoins
      onboarded
      onboardingStep
      socials(order_by: {order: asc}) {
        socialId
        order
        link
        id
        isActive
        socialAccount {
          name
          isActive
          id
        }
      }
      sections(order_by: {order: asc}, where: {isVisible: {_eq: true}}) {
        order
        isVisible
        sectionId
        section {
          id
          name
        }
      }
      username
      logins(order_by: {id: asc}) {
        verify
        userId
        loginProviderId
        providerId
        id
      }
      profileTypes(where: { isActive: { _eq: true }}, order_by: {order: asc}) {
        id
        isActive
        typeId
        order
        profileType {
          id
          name
          slug
          color
          category
          icon
        }
      }
      country {
        name
        iso
        niceName
        iso3
        id
        phonecode
      }
      myLink {
        backgroundImage
        showCvLink
        socialsAtFooter
        theme
      }
      followers: userFollowers_aggregate {
        data: aggregate {
          total: count(columns: id)
        }
      }
      followings: userFollowing_aggregate {
        data: aggregate {
          total: count(columns: id)
        }
      }
      games: games_aggregate {
        data: aggregate {
          total: count(columns: gameId)
        }
      }
    }
  }

  query GetUserAccountByUsername($username: String = "") {
    user: users_by_username(args: {url_username: $username}) {
      about
      aboutRich
      bannerImage
      bannerImageOg
      birthday
      countryId
      language
      cvLink
      email
      id
      isActive
      isBetaUser
      isVerified
      isWorker
      lastname
      name
      online
      profileImage
      profileImageOg
      showBirthday
      username
      onboarded
      onboardingStep
      profileTypes(order_by: {order: asc}, where: {isActive: {_eq: true}}) {
        id
        isActive
        order
        profileType {
          name
          slug
          category
          color
          icon
        }
      }
      sections(order_by: {order: asc}, where: {isVisible: {_eq: true}}) {
        order
        isVisible
        sectionId
        section {
          id
          name
        }
      }
      socials(order_by: {order: asc}, where: {isActive: {_eq: true}}) {
        order
        isActive
        socialAccount {
          name
        }
        link
      }
      country {
        name
        iso
        niceName
        iso3
        id
        phonecode
      }
      myLink {
        backgroundImage
        showCvLink
        socialsAtFooter
        theme
      }
      followers: userFollowers_aggregate {
        data: aggregate {
          total: count(columns: id)
        }
      }
      followings: userFollowing_aggregate {
        data: aggregate {
          total: count(columns: id)
        }
      }
    }
  }

  mutation UpdateUserAccount($id: Int!, $_set: users_set_input = {}) {
    updateUserByPk: update_users_by_pk(pk_columns: {id: $id}, _set: $_set) {
      about
      aboutRich
      bannerImage
      birthday
      countryId
      language
      email
      id
      isActive
      isBetaUser
      isVerified
      isWorker
      name
      online
      profileImage
      showBirthday
      onboarded
      onboardingStep
      socials(order_by: {order: asc}) {
        socialId
        order
        link
        id
        isActive
        socialAccount {
          name
          isActive
          id
        }
      }
      username
      logins(order_by: {id: asc}) {
        verify
        userId
        loginProviderId
        providerId
        id
      }
    }
  }

  mutation InsertUserToDeleted($object: user_to_deleted_insert_input = {}) {
    insertUserToDeleted: insert_user_to_deleted_one(object: $object) {
      userId
      requestDate
      deletionReason
      deletionDate
      deletionConfirmation
    }
  }

  mutation CancelElimination($userId: Int!) {
    cancelElimination: delete_user_to_deleted(where: {userId: {_eq: $userId}}) {
      affectedRows: affected_rows
    }
  }
`;

export const operationGetGeneral = `
  query GeneralQuery {
    countries {
      id
      iso
      iso3
      name
      niceName
      numcode
      phonecode
    }
  }

  query SocialQuery {
    socials: social_accounts {
      id
      isActive
      name
    }
  }

  query GetLoginProviders {
    loginProviders: login_providers(order_by: {id: asc}) {
      active
      id
      provider
    }
  }

  query GetGames($order_by: [games_order_by!] = {users_aggregate: {count: desc}}, $limit: Int) {
    games(where: {isActive: {_eq: true}}, order_by: $order_by, limit: $limit) {
      banner
      description
      id
      isActive
      name
      slug
      thumbnail
      thumbnailOg
      users: users_aggregate {
        data: aggregate {
          total: count(columns: userId)
        }
      }
    }
  }  

  query GetGameBySlug($slug: String = "") {
    games(where: {slug: {_eq: $slug}}) {
      thumbnail
      thumbnailOg
      slug
      name
      isActive
      id
      description
      banner
    }
  }  

  query GetUsersByGameSlug($slug: String_comparison_exp!, $clasification: clasifications_bool_exp!, $profileType: profile_types_bool_exp!, $limit: Int!, $offset: Int!, $userId: Int) {
    usersByGameSlug: user_games(where: {_and: {game: {slug: $slug}, clasification: $clasification, user: {profileTypes: {isActive: {_eq: true}, profileType: $profileType}}}}, order_by: {updatedAt: asc}, limit: $limit, offset: $offset) {
      user {
        bannerImage
        country {
          niceName
          name
        }
        language
        isBetaUser
        isFeatured
        isPartner
        isSponsored
        isVerified
        isWorker
        name
        profileImage
        username
        onboarded
        onboardingStep
        profileTypes(order_by: {order: asc}, where: {isActive: {_eq: true}}) {
          order
          profileType {
            category
            color
            icon
            name
            slug
          }
          isActive
        }
        userFollower: userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
          total: aggregate {
            count(columns: userId)
          }
        }
      }
      playerTag
      isActive
      clasification {
        name
        slug
        image
      }
    }
    pagination: user_games_aggregate(where: {_and: {game: {slug: $slug}, clasification: $clasification, user: {profileTypes: {profileType: $profileType, isActive: {_eq: true}}}}}, order_by: {updatedAt: asc}) {
      data: aggregate {
        total: count(columns: userId)
      }
    }
  }  
  
  query GetGameRoles($gameId: Int_comparison_exp!) {
    gameRoles: game_roles(where: {gameId: $gameId}) {
      description
      id
      image
      isActive
      name
    }
  }

  query GetClasifications {
    clasifications(order_by: {id: asc}, where: {isActive: {_eq: true}}) {
      description
      id
      image
      isActive
      slug
      name
    }
  }

  mutation DeleteUserLoginProvider($where: user_logins_bool_exp!) {
    deletedUserLogin: delete_user_logins(where: $where) {
      affected_rows
    }
  }  

  query GetUsersByType($limit: Int, $offset: Int) {
    usersByType: vw_users_profile_type(limit: $limit, offset: $offset) {
      category
      color
      count
      icon
      name
      slug
      users
    }
    pagination: vw_users_profile_type_aggregate {
      data: aggregate {
        total: count(columns: name)
      }
    }
  }
`

export const operationInsertUserSocials = `
  mutation InsertUserSocial($socialId: Int!, $userId: Int!, $link: String = "", $order: Int = 1) {
    insertUserSocial: insert_user_socials_one(object: {link: $link, order: $order, socialId: $socialId, userId: $userId}) {
      link
      isActive
      id
    }
  }
`

export const operationUpdateUserSocials = `
  mutation UpdateUserSocial($_set: user_socials_set_input = {}, $where: user_socials_bool_exp! = {}) {
    updateUserSocial: update_user_socials(where: $where, _set: $_set) {
      returning {
        isActive
      }
    }
  }
`

export const operationUpdateManyUserSocials = `
  mutation UpdateUserSocials($updates: [user_socials_updates!] = {where: {}}) {
    updateManySocials: update_user_socials_many(updates: $updates) {
      affected_rows
    }
  }
`

export const operationDeleteUserSocials = `
  mutation DeleteUserSocial($where: user_socials_bool_exp = {}) {
    deleteUserSocial: delete_user_socials(where: $where) {
      affectedRows: affected_rows
      returning {
        id
        socialAccount {
          name
        }
      }
    }
  }
`

export const operationGetTemporary = `
  query GetTemporaryUser($id: uuid = "") {
    temporaryUser: temporary_users_by_pk(id: $id) {
      email
      name
      providerId
      loginProvider
      id
    }
  }

  mutation DeleteTemporaryUser($object: user_logins_insert_input = {}, $id: uuid = "") {
    delete_temporary_users_by_pk(id: $id) {
      id
    }
  }
`

export const operationProfileTypes = `
  query GetProfileTypes {
    profileTypes: profile_types(where: {isActive: {_eq: true}}, order_by: {name: asc}) {
      id
      isActive
      name
      slug
      category
      color
      icon
    }
  }

  mutation InsertOrUpdateProfileTypes($objects: [user_profile_types_insert_input!] = {}) {
    updatedUserProfileTypes: insert_user_profile_types(objects: $objects, on_conflict: {constraint: user_profile_types_pkey, update_columns: [isActive, order]}) {
      affected_rows
      returning {
        id
        isActive
        typeId
        userId
        order
      }
    }
  }
`

export const operationUserSections = `
  query GetUserSections($where: user_sections_bool_exp!) {
    userSections: user_sections(order_by: {order: asc}, where: $where) {
      isVisible
      order
      userId
      sectionId
      section {
        name
        isActive
        id
      }
    }
  }

  mutation UpdateUserSectionVisibility($sectionId: Int!, $userId: Int!, $_set: user_sections_set_input = {}) {
    updatedUserSection: update_user_sections_by_pk(pk_columns: {sectionId: $sectionId, userId: $userId}, _set: $_set) {
      order
      isVisible
      sectionId
      userId
    }
  }

  mutation UpdateUserSections($updates: [user_sections_updates!] = {where: {}}) {
    updateSections: update_user_sections_many(updates: $updates) {
      affected_rows
    }
  }
`;

export const operationEditProfile = `
  mutation EditProfileAbout($pk_columns: users_pk_columns_input!, $_set: users_set_input!) {
    updatedUser: update_users_by_pk(pk_columns: $pk_columns, _set: $_set) {
      id
      name
    }
  }
`

export const operationProfileGame = `
  query GetUserGames($userId: Int!, $isEditing: Boolean = false, $isPrivate: Boolean = false) {
    userGames: getUserGames(userGame: {userId: $userId, isPrivate: $isPrivate, isEditing: $isEditing}) {
      userId
      playerTag
      isPrivate
      isActive
      id
      order
      gameId
      clasificationId
      clasification {
        slug
        name
        image
        id
        description
        isActive
      }
      game {
        banner
        id
        slug
        thumbnail
        name
      }
    }
  }

  mutation InsertUserGame($clasificationId: Int!, $gameId: Int!, $playerTag: String!, $order: Int!) {
    createUserGame(userGame: {clasificationId: $clasificationId, gameId: $gameId, playerTag: $playerTag, order: $order}) {
      userId
      playerTag
      isActive
      id
      gameId
      clasificationId
      order
    }
  }

  mutation UpdateUserGame($clasificationId: Int!, $gameId: Int!, $playerTag: String!, $isActive: Boolean = false, $isPrivate: Boolean = false) {
    updateUserGame(userGame: {clasificationId: $clasificationId, gameId: $gameId, playerTag: $playerTag, isPrivate: $isPrivate, isActive: $isActive}) {
      userId
      playerTag
      isActive
      id
      gameId
      clasificationId
    }
  }

  mutation DeleteUserGame($gameId: Int!, $userId: Int!) {
    deleteUserGame: delete_user_games_by_pk(gameId: $gameId, userId: $userId) {
      id
    }
  }

  mutation UpdateUserGamesOrder($updates: [user_games_updates!]!) {
    updateManyGamesOrder: update_user_games_many(updates: $updates) {
      affected_rows
    }
  }
`

export const operationProfileAchievement = `
  query GetUserAchievements($where: user_achievements_bool_exp!, $limit: Int!, $offset: Int!) {
    userAchievements: user_achievements(where: $where, limit: $limit, offset: $offset, order_by: [{isFeature: desc}, {createdAt: desc}]) {
      userId
      isActive
      achievementId
      isFeature
      achievement {
        date
        description
        descriptionRich
        gameId
        id
        image
        link
        title
        teamId
        gameRoleId
        game {
          banner
          name
          slug
          thumbnail
        }
      }
    }
    pagination: user_achievements_aggregate(where: $where) {
      data: aggregate {
        total: count(columns: achievementId)
      }
    }
  }

  mutation InsertUserAchievement($object: user_achievements_insert_input = {}) {
    insertUserAchievement: insert_user_achievements_one(object: $object) {
      userId
      achievementId
      isActive
      isFeature
    }
  }

  mutation InsertAchievement($object: achievements_insert_input!) {
    insertAchievement: insert_achievements_one(object: $object) {
      date
      description
      gameRoleId
      gameId
      id
      image
      link
      title
      teamId
    }
  }

  mutation UpdateUserAchievement($achievementId: Int!, $userId: Int!, $_set: user_achievements_set_input!) {
    updateUserAchievement: update_user_achievements_by_pk(pk_columns: {achievementId: $achievementId, userId: $userId}, _set: $_set) {
      achievementId
      isActive
      isFeature
      userId
    }
  }

  mutation UpdateAchievement($id: Int!, $_set: achievements_set_input!) {
    updateAchievement: update_achievements_by_pk(pk_columns: {id: $id}, _set: $_set) {
      date
      description
      gameId
      gameRoleId
      id
      image
      link
      teamId
      title
    }
  }  

  mutation DeleteUserAchievement($achievementId: Int!, $userId: Int!) {
    deletedAchievement: delete_achievements_by_pk(id: $achievementId) {
      id
    }
    deletedUserAchievement: delete_user_achievements_by_pk(achievementId: $achievementId, userId: $userId) {
      userId,
      achievementId
    }
  }
`

export const operationProfileEvent = `
  query GetEventTypes {
    eventTypes: event_types(where: {active: {_eq: true}}) {
      name
      id
      active
    }
  }

  query GetUserEvents($where: events_bool_exp, $limit: Int!, $offset: Int!) {
    userEvents: events(order_by: {date: desc}, limit: $limit, offset: $offset, where: $where) {
      date
      image
      id
      game {
        banner
        name
        slug
        thumbnail
      }
      eventTypeId
      eventCategoryId
      eventTagId
      gameId
      link
      isActive
      profileType {
        name
        slug
        color
        category
        icon
      }
      participationTypeId
      title
      slug
      eventType {
        active
        name
      }
      eventTag {
        name
        id
      }
      eventCategory {
        id
        name
      }
      description
      isParent
      parentEventId
      userId
      owner {
        name
        username
        profileImage
      }
      isFeature
    }
    pagination: events_aggregate(where: $where) {
      data: aggregate {
        total: count(columns: userId)
      }
    }
  }  

  mutation InsertUserEvent($object: user_events_insert_input = {}) {
    insertUserEvent: insert_user_events_one(object: $object) {
      userId
      eventId
      isActive
      isFeature
    }
  }

  mutation InsertEvent($object: events_insert_input!) {
    insertEvent: insert_events_one(object: $object) {
      date
      gameId
      id
      image
      link
      title
      slug
      eventTypeId
      participationTypeId
    }
  }

  mutation UpdateUserEvent($eventId: Int!, $userId: Int!, $_set: user_events_set_input!) {
    updateUserEvent: update_user_events_by_pk(pk_columns: {eventId: $eventId, userId: $userId}, _set: $_set) {
      eventId
      isActive
      isFeature
      userId
    }
  }

  mutation UpdateEvent($id: Int!, $_set: events_set_input!) {
    updateEvent: update_events_by_pk(pk_columns: {id: $id}, _set: $_set) {
      date
      gameId
      id
      image
      link
      title
      slug
      eventTypeId
      eventTagId
      eventCategoryId
      participationTypeId
      isActive
    }
  }  

  mutation DeleteUserEvent($eventId: Int!) {
    deletedEvent: delete_events_by_pk(id: $eventId) {
      id
    }
  }
`

export const operationProfilePartner = `
  query GetUserPartners($where: user_partners_bool_exp!, $limit: Int!, $offset: Int!) {
    userPartners: user_partners(order_by: {isFeature: desc, partner: { createdAt: desc }}, where: $where, limit: $limit, offset: $offset) {
      userId
      isActive
      isFeature
      partnerId
      partner {
        id
        image
        link
        name
      }
    }
    pagination: user_partners_aggregate(where: $where) {
      data: aggregate {
        total: count(columns: partnerId)
      }
    }
  }

  mutation InsertUserPartner($object: user_partners_insert_input = {}) {
    insertUserPartner: insert_user_partners_one(object: $object) {
      userId
      partnerId
      isActive
      isFeature
    }
  }

  mutation InsertPartner($object: partners_insert_input!) {
    insertPartner: insert_partners_one(object: $object) {
      id
      image
      link
      name
      isActive
    }
  }  

  mutation UpdateUserPartner($partnerId: Int!, $userId: Int!, $_set: user_partners_set_input!) {
    updateUserPartner: update_user_partners_by_pk(pk_columns: {partnerId: $partnerId, userId: $userId}, _set: $_set) {
      partnerId
      isActive
      isFeature
      userId
    }
  }

  mutation UpdatePartner($id: Int!, $_set: partners_set_input!) {
    updatePartner: update_partners_by_pk(pk_columns: {id: $id}, _set: $_set) {
      id
      image
      link
      isActive
      name
    }
  } 

  mutation DeleteUserPartner($partnerId: Int!, $userId: Int!) {
    deletedPartner: delete_partners_by_pk(id: $partnerId) {
      id
    }
    deletedUserPartner: delete_user_partners_by_pk(partnerId: $partnerId, userId: $userId) {
      id
    }
  }
`

export const operationProfileVideo = `
  query GetUserVideos($where: user_videos_bool_exp!) {
    userVideos: user_videos(where: $where) {
      userId
      isActive
      youtubeChannelId
      youtubeChannel {
        channelLink
        channelId
        template
        isActive
      }
    }
  }

  mutation InsertUserVideo($object: user_videos_insert_input = {}) {
    insertUserVideo: insert_user_videos_one(object: $object) {
      userId
      isActive
      youtubeChannelId
      id
    }
  }

  mutation InsertYoutubeChannel($object: youtube_channels_insert_input = {}) {
    insertYoutubeChannel: insert_youtube_channels_one(object: $object) {
      channelLink
      isActive
      template
      id
    }
  }

  mutation UpdateUserVideo($userId: Int!, $youtubeChannelId: Int!, $_set: user_videos_set_input!) {
    updateUserVideo: update_user_videos_by_pk(pk_columns: {userId: $userId, youtubeChannelId: $youtubeChannelId}, _set: $_set) {
      isActive
      userId
      youtubeChannelId
    }
  }

  mutation UpdateYoutubeChannel($id: Int!, $_set: youtube_channels_set_input!) {
    updateYoutubeChannel: update_youtube_channels_by_pk(pk_columns: {id: $id}, _set: $_set) {
      id
      isActive
      channelLink
      template
    }
  }
  
  mutation DeleteUserVideo($youtubeChannelId: Int!, $userId: Int!) {
    deletedUserVideo: delete_user_videos_by_pk(youtubeChannelId: $youtubeChannelId, userId: $userId) {
      id
    }
    deletedYoutubeChannel: delete_youtube_channels_by_pk(id: $youtubeChannelId) {
      id
    }
  }
`

export const operationProfileFollow = `
  query GetFollowUser($followingId: Int, $userId: Int) {
    userFollow: user_follows(where: {followingId: {_eq: $followingId}, userId: {_eq: $userId}}) {
      userId
      followingId
      id
    }
  }

  mutation Follow($followingId: Int) {
    follow: insert_user_follows_one(object: {followingId: $followingId}) {
      id
      followingId
      userId
    }
  }

  mutation Unfollow($userId: Int, $followingId: Int) {
    unfollow: delete_user_follows(where: {userId: {_eq: $userId}, followingId: {_eq: $followingId}}) {
      affectedRows: affected_rows
    }
  }
`

export const operationProfileFan = `
  query GetFanUser($idolUserId: Int, $fanUserId: Int) {
    userFan: user_fans(where: {idolUserId: {_eq: $idolUserId}, fanUserId: {_eq: $fanUserId}}) {
      fanUserId
      idolUserId
      id
    }
  }

  mutation Fan($idolUserId: Int) {
    follow: insert_user_fans_one(object: {idolUserId: $idolUserId}) {
      id
      idolUserId
      fanUserId
    }
  }

  mutation Unfan($fanUserId: Int, $idolUserId: Int) {
    unfollow: delete_user_fans(where: {fanUserId: {_eq: $fanUserId}, idolUserId: {_eq: $idolUserId}}) {
      affectedRows: affected_rows
    }
  }
`

export const operationProfileVouch = `
  query GetVouchUser($vouchedUserId: Int, $vouchingUserId: Int) {
    userVouch: user_vouchs(where: {vouchedUserId: {_eq: $vouchedUserId}, vouchingUserId: {_eq: $vouchingUserId}}) {
      vouchingUserId
      vouchedUserId
      id
    }
  }

  mutation Vouch($vouchedUserId: Int, $testimonial: json) {
    follow: insert_user_vouchs_one(object: {vouchedUserId: $vouchedUserId, testimonial: $testimonial}) {
      id
      vouchedUserId
      vouchingUserId
      testimonial
    }
  }  
`